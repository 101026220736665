import React from 'react';
import { Link } from 'react-router-dom';
import './sidebar.css'; // Importa los estilos de la barra lateral si los tienes

const Sidebar = () => {
  return (
    <div className="sidebar align-content-center px-4">
      <ul className="sidebar-list">
      <li>
          <Link to="/principal">MENU</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/about">Series</Link>
        </li>
        <li>
          <Link to="/contact">Furniture</Link>
        </li>
        <li>
          <Link to="/contact">Pre-order</Link>
        </li>
        <li>
          <Link to="/contact">On-line</Link>
        </li>
        <li>
          <Link to="/contact">Terms & Care</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
        {/* Agrega más elementos según tu aplicación */}
      </ul>
    </div>
  );
};

export default Sidebar;