import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/home/home.js';
import Principal from './components/principal/principal.js';
import About from './components/about/about.js'; // Importa el componente About
import './App.css'; // Importa los estilos globales si los tienes

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/principal" element={<Principal />} />
        <Route path="/about" element={<About />} /> {/* Ruta para el componente About */}
      </Routes>
    </Router>
  );
}

export default App;
