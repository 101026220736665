// About.js
import React from 'react';
import Bola from './img/bola.png';  // Asegúrate de que la ruta a la imagen sea correcta
import { Row, Col } from 'react-bootstrap'; // Importa las clases de Bootstrap
import Layout from '../layouts/layout.js';

const About = () => {
  // Contenido dinámico
  const content = {
    title: "Piece of arte",
    description: `Piece of arte es una firma de joyería escultórica fundada en Santiago de Chile el 2018, por el diseñador Esteban Arteaga. La filosofía de Piece of arte es crear un universo sofisticado y eterno a través de redescubrir cada pieza creada como una obra de arte. Esta se caracteriza por el trabajo artesanal y producción bajo pequeñas series, en donde se utiliza un selecto grupo de materiales, tomando la cerámica como base junto a metales nobles y piedras como el Lapislázuli, Cuarzos y Perlas. Nuestra firma es consciente de que gracias al indumento se puede contar una historia que conecte con el usuario, donde el mensaje final al igual que en una obra artística tenga diversos sentidos en base a quien la observe o utilice. Es así como la estética debe estar al servicio de la funcionalidad y el uso no debe sacrificar al diseño. Creando piezas esculturales para mentes contemporáneas.`
  };

  return (
    <Layout>
      <Row className="about-content text-center">
        <Col>
          <img src={Bola} alt="Bola" className="img-fluid" />
          <p>{content.description}</p>
        </Col>
      </Row>
    </Layout>
  );
};

export default About;
