import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './home.css';
import logo from './img/logo.png';

const Home = () => {
  const [slideUp, setSlideUp] = useState(false);
  const [hidden, setHidden] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setSlideUp(true);
  }

  useEffect(() => {
    if (slideUp) {
      const timer = setTimeout(() => {
        setHidden(true);
        navigate('/principal'); // Navegar a otra página después de la animación
      }, 1000); // La duración debe coincidir con la duración de la animación

      return () => clearTimeout(timer);
    }
  }, [slideUp, navigate]);

  return (
    !hidden && (
      <div className={`home-container center ${slideUp ? 'slide-up' : ''}`}>
        <div className='container-xxl flex row justify-content-center'>
          <img src={logo} className='logo col-2' alt='Logo'/>
          <p className='text-white text-end w-50 align-content-center m-0' onClick={handleClick}>OPEN</p>
        </div>
      </div>
    )
  );
}

export default Home;
